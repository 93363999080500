
import {
    computed, defineComponent, onMounted, reactive, ref,
} from 'vue';

import FullCalendar from '@fullcalendar/vue3';
import { CalendarOptions, EventHoveringArg, EventInput } from '@fullcalendar/core';
import interactionPlugin, { Draggable, DropArg } from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import scrollGridPlugin from '@fullcalendar/scrollgrid';

import Screen from '@/components/layout/Screen.vue';
import SubHeader from '@/components/SubHeader.vue';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import DropdownAutocompleteSingleSelect from '@/components/dropdown/DropdownAutocompleteSingleSelect.vue';
import TextInput from '@/components/inputs/TextInput.vue';

import useHtmlClassHelper from '@/composable/useHtmlClassHelper';
import { formatMilitaryTimeFromMinutes, toMidnight, changeDays } from '@/functions/date';

import CoreStore from '@/store/CoreStore';

import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';
import ReservationService from '@/services/ReservationService';
import LocationService from '@/services/LocationService';
import CarrierService from '@/modules/master-data/services/CarrierService';

import ReservationlessShipmentDTO from '@/dtos/ReservationlessShipmentDTO';

import ExistingReservationCard from './components/ExistingReservationCard.vue';
import ReservationlessShipmentCard from './components/ReservationlessShipmentCard.vue';

import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';
import useValidator from '@/validation/useValidator';

import { NonWorkDay } from '@/domain/Dates/NonWorkDays';
import Location from '@/domain/Location';
import Carrier from '@/domain/Carrier';
import PlannedReservation from '@/domain/PlannedReservation';
import ReservationType from '@/domain/enums/ReservationType';
import Reservation from '@/domain/Reservation';
import Direction from '@/domain/enums/Direction';

type State = {
    loading: boolean;
    allReservationlessShipments: Array<ReservationlessShipmentDTO>;
    fromLocations: Array<Location>;
    carriers: Array<Carrier>;
    showPlannedReservationModal: boolean;
    plannedReservation: PlannedReservation;
    dockLocation: Location;
    allDirectionOptions: Array<{ value: number, text: string }>;
    selectedDirection: Direction | number;
    searchText: string;
    savedSearch: string;
};

export default defineComponent({
    name: 'shipping-planner-screen',
    components: {
        Screen,
        SubHeader,
        DropdownAutocompleteSingleSelect,
        TextInput,
        BSpinner,
        FullCalendar,
        ExistingReservationCard,
        ReservationlessShipmentCard,
    },
    setup() {
        const { profileStore } = CoreStore.getInstance();
        const { addClass, removeClass } = useHtmlClassHelper();
        const { confirm } = useDialogBox();
        const newPlannedReservationValidation = useValidator<PlannedReservation>('new-planned-reservation');
        const newPlannedReservationValidationResult = newPlannedReservationValidation.validationResult;

        const fullCalendar = ref<InstanceType<typeof FullCalendar>>();

        const reservationService = new ReservationService();
        const locationService = new LocationService();
        const carrierService = new CarrierService();

        const contentCardColor = '#e2e2e2';

        const state = reactive<State>({
            loading: false,
            allReservationlessShipments: [],
            fromLocations: [],
            carriers: [],
            showPlannedReservationModal: false,
            plannedReservation: new PlannedReservation(),
            dockLocation: profileStore.userLocation,
            allDirectionOptions: [
                {
                    value: Direction.InboundAndOutbound,
                    text: getTitleCaseTranslation('core.common.all'),
                },
                {
                    value: Direction.Inbound,
                    text: getTitleCaseTranslation('core.common.inbound'),
                },
                {
                    value: Direction.Outbound,
                    text: getTitleCaseTranslation('core.common.outbound'),
                },
            ],
            selectedDirection: Direction.InboundAndOutbound,
            searchText: '',
            savedSearch: '',
        });

        const nonWorkDays = computed((): Array<number> => state.dockLocation.nonWorkingTimeSet?.nonWorkDays.map((d) => d.nonWorkDay) || []);
        const holidays = computed((): Array<Date> => state.dockLocation.nonWorkingTimeSet?.nonWorkDates.map((d) => new Date(d.nonWorkDate)) || []);

        const workingDaysOfWeek = computed((): number[] => Object.keys(NonWorkDay)
            .map((val) => Number(val))
            .filter((val) => Number.isInteger(val) && !nonWorkDays.value.includes(val)));

        const dockStartTime = computed((): string => (!state.dockLocation.isOpen24Hours ? state.dockLocation.openTime.toTimeString() : '00:00:00'));
        const dockEndTime = computed((): string => (!state.dockLocation.isOpen24Hours ? state.dockLocation.closeTime.toTimeString() : '24:00:00'));
        const eventDuration = computed((): string => (state.dockLocation.timeslotDuration ? formatMilitaryTimeFromMinutes(state.dockLocation.timeslotDuration) : '01:00:00'));

        const userServiceCenters = computed(() => profileStore.locations.filter((location) => location.id === profileStore.userLocation.id || location.isServiceCenter));

        function correctDirection(shipment: ReservationlessShipmentDTO) {
            return state.selectedDirection === Direction.InboundAndOutbound || shipment.direction === state.selectedDirection;
        }

        function containsSearch(shipment: ReservationlessShipmentDTO) {
            return state.savedSearch === '' || shipment.transactions
                .some((t) => t.fromLocationName.toLowerCase().includes(state.savedSearch.toLowerCase())
                    || t.toLocationName.toLowerCase().includes(state.savedSearch.toLowerCase()));
        }

        const filteredReservationlessShipments = computed(() => state.allReservationlessShipments.filter(correctDirection).filter(containsSearch));

        function formatDateAndTime(date: Date) {
            const pad = (value: number) => (value < 10 ? `0${value}` : value);

            const hour = date.getHours();
            const minute = pad(date.getMinutes());

            const period = hour < 12 ? 'AM' : 'PM';
            const formattedHour = hour % 12 || 12;

            return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${formattedHour}:${minute} ${period}`;
        }

        function dateAndTimeMatch(firstDate: Date, secondDate: Date): boolean {
            return (
                firstDate.getFullYear() === secondDate.getFullYear()
                && firstDate.getMonth() === secondDate.getMonth()
                && firstDate.getDate() === secondDate.getDate()
                && firstDate.getHours() === secondDate.getHours()
                && firstDate.getMinutes() === secondDate.getMinutes()
            );
        }

        onMounted(async () => {
            const draggableEl = document.getElementById('reservationless-shipments');

            if (draggableEl) {
                // eslint-disable-next-line no-new
                new Draggable(draggableEl, {
                    itemSelector: '.reservationless-shipment',
                });

                // eslint-disable-next-line no-new
                new Draggable(draggableEl, {
                    itemSelector: '.planned-reservation',
                });
            }

            state.fromLocations = await locationService.getCanReceiveFromLocations(state.dockLocation.id);

            const carriersResponse = await carrierService.getAllCarriers();
            if (carriersResponse.success) {
                state.carriers = carriersResponse.carriers;
            }
        });

        function showPlannedReservationModal(reservationDateTime: Date) {
            state.plannedReservation = new PlannedReservation({ toLocation: state.dockLocation, reservationDateTime });
            state.plannedReservation.toLocation = state.dockLocation;
            state.plannedReservation.reservationDateTime = reservationDateTime;
            state.showPlannedReservationModal = true;
        }

        function closePlannedReservationModal() {
            state.showPlannedReservationModal = false;
        }

        async function savePlannedReservation() {
            newPlannedReservationValidation.validateForm(state.plannedReservation);
            if (newPlannedReservationValidation.validationResult.isValid) {
                await reservationService.addPlannedReservation(state.plannedReservation);
                fullCalendar.value?.getApi().refetchEvents();
                state.showPlannedReservationModal = false;
            }
        }

        async function selectFromLocation(location: Location) {
            if (location) {
                state.plannedReservation!.fromLocation = location;
            } else {
                state.plannedReservation!.fromLocation = new Location();
            }
        }

        async function selectCarrier(carrier: Carrier | null) {
            if (carrier) {
                state.plannedReservation!.carrier = carrier;
            } else {
                state.plannedReservation!.carrier = new Carrier();
            }
        }

        function getReservationTitle(reservation: Reservation) {
            if (reservation.reservationType === ReservationType.Planned) {
                return getTitleCaseTranslation('core.domain.inboundReceipt');
            }
            if (reservation.direction === Direction.Inbound) {
                return `${getTitleCaseTranslation('core.domain.receiptWithNumberSymbol')}${reservation.shipmentId}`;
            }
            return `${getTitleCaseTranslation('core.domain.shipmentWithNumberSymbol')}${reservation.shipmentId}`;
        }

        function onEventMouseEnter(mouseEnterInfo: EventHoveringArg) {
            addClass(mouseEnterInfo.el as HTMLElement, 'calendar-content-grow');
            addClass((mouseEnterInfo.el as HTMLElement).parentElement as HTMLElement, 'calendar-content-overlap');
        }

        function onEventMouseLeave(mouseLeaveInfo: EventHoveringArg) {
            removeClass(mouseLeaveInfo.el as HTMLElement, 'calendar-content-grow');
            removeClass((mouseLeaveInfo.el as HTMLElement).parentElement as HTMLElement, 'calendar-content-overlap');
        }

        async function onEventDrop(dropInfo: DropArg) {
            state.loading = true;

            const droppedEvent = JSON.parse(dropInfo.draggedEl.dataset.event!);

            let saveEvent = true;

            const { calendar } = dropInfo.view;
            const currentEvents = calendar.getEvents();

            const eventDateOnly = new Date(dropInfo.date);
            eventDateOnly.setHours(0, 0, 0, 0);

            const isNonWorkDay = nonWorkDays.value.includes(dropInfo.date.getDay());
            const isHoliday = holidays.value.some((holiday) => holiday.getTime() === eventDateOnly.getTime());
            const isOutsideWorkingHours = !state.dockLocation.isOpen24Hours
                && (dropInfo.date.getHours() < state.dockLocation.openTime.getHours() || dropInfo.date.getHours() > state.dockLocation.closeTime.getHours());

            if (isNonWorkDay || isHoliday || isOutsideWorkingHours) {
                saveEvent = await confirm({
                    title: getTitleCaseTranslation('core.common.confirmReservation'),
                    message: getTranslation('core.validation.shippingPlannerOutsideBusinessHours'),
                    vHtml: true,
                });
            }

            const currentLoads = currentEvents.filter((currentEvent) => !currentEvent.extendedProps.isHoliday && dateAndTimeMatch(currentEvent.start!, dropInfo.date)).length;
            const maxLoads = state.dockLocation.maxLoadPerTimeslot ?? 3;

            if (currentLoads >= maxLoads) {
                let hours = dropInfo.date.getHours();
                const ampm = hours >= 12 ? 'PM' : 'AM';
                hours = hours % 12 || 12;

                const formattedHours = `${hours}:${dropInfo.date.getMinutes().toString().padStart(2, '0')} ${ampm}`;

                saveEvent = await confirm({
                    title: getTitleCaseTranslation('core.common.confirmReservation'),
                    message: getTranslation('core.validation.shippingPlannerMaxLoadsExceeded', formattedHours),
                    vHtml: true,
                });
            }

            if (droppedEvent.extendedProps.isPlannedReservation && saveEvent) {
                showPlannedReservationModal(dropInfo.date);
                state.loading = false;
                return;
            }

            if (saveEvent) {
                const shipmentId: number = +droppedEvent.id;
                await reservationService.addShipmentReservation(shipmentId, dropInfo.date);
                const index = state.allReservationlessShipments.findIndex((shipment) => shipment.shipmentId === shipmentId);
                state.allReservationlessShipments.splice(index, 1);
                calendar.refetchEvents();
            }
            state.loading = false;
        }

        async function getHolidays(
            arg: { start: Date; end: Date; startStr: string; endStr: string; timeZone: string },
            successCallback: (events: EventInput[]) => void,
            failureCallback: (error: Error) => void,
        ): Promise<void> {
            try {
                const currentHolidays = holidays.value
                    .filter((holiday) => holiday.getTime() >= arg.start.getTime() && holiday.getTime() <= arg.end.getTime())
                    .map((holiday) => {
                        const [startHours, startMinutes, startSeconds] = dockStartTime.value.split(':');
                        const [endHours, endMinutes, endSeconds] = dockEndTime.value.split(':');

                        return {
                            start: new Date(new Date(holiday).setHours(parseInt(startHours, 10), parseInt(startMinutes, 10), parseInt(startSeconds, 10), 0)),
                            end: new Date(new Date(holiday).setHours(parseInt(endHours, 10), parseInt(endMinutes, 10), parseInt(endSeconds, 10), 0)),
                            allDay: false,
                            editable: false,
                            display: 'background',
                            classNames: ['fc-non-business'],
                            extendedProps: {
                                isHoliday: true,
                            },
                        };
                    });

                successCallback(currentHolidays);
            } catch (error) {
                failureCallback(new Error('Error fetching holidays'));
            }
        }

        async function getReservations(
            arg: { start: Date; end: Date; startStr: string; endStr: string; timeZone: string },
            successCallback: (events: EventInput[]) => void,
            failureCallback: (error: Error) => void,
        ): Promise<void> {
            try {
                state.loading = true;

                const reservationlessStart = toMidnight(changeDays(new Date(arg.start), -7));
                const reservationlessEnd = toMidnight(changeDays(new Date(arg.end), 14));

                state.allReservationlessShipments = await reservationService.getReservationlessShipmentsByServiceCenter(state.dockLocation.id, reservationlessStart, reservationlessEnd);

                const currentReservations = await reservationService.getReservationsByServiceCenter(state.dockLocation.id, arg.start, arg.end);

                const currentEvents = currentReservations.map((reservation) => {
                    const eventEnd = new Date(reservation.reservationDateTime!);
                    const timeslotDuration = state.dockLocation.timeslotDuration ?? 60;

                    eventEnd.setHours(reservation.reservationDateTime!.getHours(), reservation.reservationDateTime!.getMinutes() + timeslotDuration, 0, 0);

                    return {
                        title: getReservationTitle(reservation),
                        start: reservation.reservationDateTime,
                        end: eventEnd,
                        allDay: false,
                        editable: false,
                        backgroundColor: contentCardColor,
                        borderColor: contentCardColor,
                        expandRows: true,
                        display: state.selectedDirection === Direction.InboundAndOutbound || state.selectedDirection === reservation.direction ? 'auto' : 'none',
                        extendedProps: {
                            isHoliday: false,
                            reservation,
                        },
                    };
                });
                state.loading = false;
                successCallback(currentEvents);
            } catch (error) {
                failureCallback(new Error('Error fetching events'));
            }
        }

        const calendarOptions = computed(
            (): CalendarOptions => ({
                schedulerLicenseKey: '0548597005-fcs-1706281034',
                droppable: true,
                initialView: 'timeGridSevenDay',
                views: {
                    timeGridSevenDay: {
                        buttonText: 'rolling week',
                        dayMinWidth: 150,
                        duration: { days: 7 },
                        type: 'timeGrid',
                    },
                },
                allDaySlot: false,
                plugins: [interactionPlugin, scrollGridPlugin, timeGridPlugin],
                height: '100%',
                headerToolbar: {
                    left: 'prev,next today',
                    center: 'title',
                    right: 'timeGridSevenDay,timeGridDay,timeGridWeek',
                },
                stickyHeaderDates: true,
                expandRows: true,
                eventSources: [
                    {
                        events: (arg, successCallback, failureCallback) => getReservations(arg, successCallback, failureCallback),
                    },
                    {
                        events: (arg, successCallback, failureCallback) => getHolidays(arg, successCallback, failureCallback),
                    },
                ],
                scrollTime: !state.dockLocation.isOpen24Hours ? state.dockLocation.openTime.toTimeString() : '06:00:00',
                slotDuration: eventDuration.value,
                slotEventOverlap: false,
                businessHours: {
                    daysOfWeek: workingDaysOfWeek.value,
                    startTime: dockStartTime.value,
                    endTime: dockEndTime.value,
                },
                eventMouseEnter: (mouseEnterInfo) => onEventMouseEnter(mouseEnterInfo),
                eventMouseLeave: (mouseLeaveInfo) => onEventMouseLeave(mouseLeaveInfo),
                drop: (dropInfo) => onEventDrop(dropInfo),
            }),
        );

        const plannedReservationText = `{ 
            "title": "${getTitleCaseTranslation('core.common.newReservation')}",
            "duration": "${eventDuration.value}",
            "create":false,
            "backgroundColor": "#6b8e23",
            "borderColor": "#e2e2e2",
            "extendedProps":{
                "dockName":"",
                "isPlannedReservation":true
            }}`;

        function changeUserServiceCenter(serviceCenterId: number) {
            state.dockLocation = userServiceCenters.value.find((location) => location.id === serviceCenterId)!;
        }

        function filterExistingReservations(direction: Direction) {
            if (direction === Direction.InboundAndOutbound) {
                fullCalendar.value!.getApi().getEvents().forEach((event) => {
                    event.setProp('display', null);
                });
            } else {
                fullCalendar.value!.getApi().getEvents().forEach((event) => {
                    if (!event.extendedProps.isHoliday && event.extendedProps.reservation?.direction !== direction) {
                        event.setProp('display', 'none');
                    } else {
                        event.setProp('display', null);
                    }
                });
            }
        }

        function searchReservationlessShipments() {
            state.savedSearch = state.searchText;
        }

        function clearReservationlessShipmentsSearch() {
            state.searchText = '';
            state.savedSearch = '';
        }

        return {
            fullCalendar,
            ReservationType,
            formatDateAndTime,
            getTitleCaseTranslation,
            getTranslation,
            state,
            filteredReservationlessShipments,
            calendarOptions,
            plannedReservationText,
            closePlannedReservationModal,
            selectFromLocation,
            selectCarrier,
            savePlannedReservation,
            newPlannedReservationValidationResult,
            userServiceCenters,
            changeUserServiceCenter,
            filterExistingReservations,
            searchReservationlessShipments,
            clearReservationlessShipmentsSearch,
        };
    },
});
