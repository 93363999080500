
import { defineComponent, computed } from 'vue';
import { getTitleCaseTranslation } from '@/services/TranslationService';
import ReservationlessShipmentDTO from '@/dtos/ReservationlessShipmentDTO';
import Direction from '@/domain/enums/Direction';
import Reservation from '@/domain/Reservation';
import ReservationStatus from '@/domain/enums/ReservationStatus';
import ReservationType from '@/domain/enums/ReservationType';

export default defineComponent({
    name: 'reservationless-shipment-card',
    components: {},
    props: {
        shipment: {
            type: ReservationlessShipmentDTO,
            required: true,
        },
    },
    setup(props) {
        const headerColor = computed(() => (props.shipment.direction === Direction.Inbound ? '#4b61a8' : '#c48c17'));
        const reservationTitle = computed(() => (props.shipment.direction === Direction.Inbound
            ? getTitleCaseTranslation('core.domain.receiptWithNumberSymbol')
            : getTitleCaseTranslation('core.domain.shipmentWithNumberSymbol')));

        const eventData = computed(() => ({
            id: props.shipment.shipmentId,
            title: `${reservationTitle.value}${props.shipment.shipmentId}`,
            editable: false,
            backgroundColor: headerColor.value,
            borderColor: headerColor.value,
            expandRows: true,
            create: false,
            extendedProps: {
                isHoliday: false,
                reservation: new Reservation({
                    id: 0,
                    shipmentId: props.shipment.shipmentId,
                    reservationDateTime: new Date(),
                    direction: props.shipment.direction,
                    carrier: props.shipment.carrier,
                    reservationType: ReservationType.Shipment,
                    status: ReservationStatus.OnTime,
                    transactions: props.shipment.transactions,
                }),
            },
        }));

        return {
            getTitleCaseTranslation,
            eventData,
            headerColor,
            reservationTitle,
        };
    },
});
