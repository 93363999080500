import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f8d182f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-event"]
const _hoisted_2 = { class: "reservationless-shipment-head" }
const _hoisted_3 = { class: "reservationless-shipment-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "reservationless-shipment",
    style: _normalizeStyle({ background: _ctx.headerColor }),
    draggable: "true",
    "data-event": JSON.stringify(_ctx.eventData)
  }, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.reservationTitle) + _toDisplayString(_ctx.shipment.shipmentId), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shipment.transactions, (transaction) => {
        return (_openBlock(), _createElementBlock("div", {
          key: transaction.toLocationId.toString() + transaction.fromLocationId.toString()
        }, " #" + _toDisplayString(transaction.id) + " " + _toDisplayString(transaction.fromLocationName) + " → " + _toDisplayString(transaction.toLocationName), 1))
      }), 128))
    ])
  ], 12, _hoisted_1))
}